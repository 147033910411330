import {
    List,
    ListProps,
    Datagrid,
    TextField,
    DateInput,
    BooleanInput,
    AutocompleteArrayInput,
    NumberField,
    TopToolbar,
    ExportButton,
    FilterButton,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const channels = [
    "amazon",
    "amazon-ca",
    "acenda",
    "target",
    "walmart",
    "walmart-ca",
    "overstock",
    "wayfair",
    "ebay",
    "bass-pro",
    "shopify",
    "google-shopping",
    "tractor-supply",
    "thehomedepot",
    "bigcommerce",
    "lowes",
    "macys",
    "kohls",
    "newegg",
    "kroger",
    "bed-bath-beyond",
    "belk",
    "woocommerce"
]

const stores = window.localStorage.getItem('stores') ? JSON.parse(window.localStorage.getItem("stores") as string).map((store: string) => ({ id: store, name: store })) : [];

const listFilters = [
    <AutocompleteArrayInput choices={stores} source="store" alwaysOn ></AutocompleteArrayInput>,
    <DateInput source="from" label="From" />,
    <DateInput source="to" label="To" />,
    <BooleanInput source="daily"></BooleanInput>,
];

const SaleList = (props: ListProps) => {

    if (!window.localStorage.getItem('stores')) {
        var axios = require('axios');
        var config = {
            method: 'post',
            url: process.env.REACT_APP_SERVER_URL + 'stores',
            headers: {
                'Content-Type': 'application/json',
                'authorization': 'Bearer ' + window.localStorage.getItem("accessToken"),
            },
            data: {
                "store": "acendacom",
                "service": "acenda-fees"
            }
        };

        axios(config)
            .then(function (response: any) {
                window.localStorage.setItem('stores', JSON.stringify(response.data));
                window.location.reload()
            })
            .catch(function (error: any) {
                console.log(error);
            });
    }
    const ListActions = () => (
        <TopToolbar>
            <ExportButton maxResults={1000000} />
            <FilterButton/>
        </TopToolbar>
    );
    return (
        <List actions={<ListActions />}
            {...props}
            filters={listFilters}
            perPage={25}
            sort={{ field: 'id', order: 'desc' }}
        >
            <Datagrid rowClick="expand">
                <TextField source="id" />
                <TextField source="store_name" />
                <TextField source="month" />
                <TextField source="date" />
                {channels.map((channel, index) => (
                    <NumberField key={index} source={channel}></NumberField>
                ))}
            </Datagrid>
        </List>
    );
};

export default SaleList;
