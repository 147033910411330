import {
    BooleanInput,
    DateField,
    Edit,
    EditProps,
    FormWithRedirect,
    Labeled,
    NumberInput,
    SelectInput,
    TextField,
    TextInput,
    Toolbar,
    useTranslate,
} from 'react-admin';
import {
    Card,
    CardContent,
    Box,
    Grid,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';



const useEditStyles = makeStyles({
    root: { alignItems: 'flex-start' },
});

const Spacer = () => <Box m={1}>&nbsp;</Box>;

const QuickBookAccountForm = (props: any) => {
    const translate = useTranslate();
    return (
        <FormWithRedirect
            {...props}
            render={(formProps: any) => (
                <Box maxWidth="50em">
                    <Card>
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} md={8}>
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.commands.section.order'
                                        )}
                                    </Typography>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <Labeled
                                                source="store_name"
                                                resource="store_name"
                                            >
                                                <TextField
                                                    source="store_name"
                                                    resource="store_name"
                                                    record={formProps.record}
                                                />
                                            </Labeled>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <Labeled
                                                source="customer_id"
                                                resource="customer_id"
                                            >
                                                <TextInput
                                                    source="customer_id"
                                                    resource="customer_id"
                                                    record={formProps.record}
                                                />
                                            </Labeled>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item md={6}>
                                            <NumberInput
                                                resource="sales_percentage"
                                                source="sales_percentage"
                                            />
                                        </Grid>
                                        <Grid item md={6}>
                                            <NumberInput
                                                resource="fixed_monthly_fee"
                                                source="fixed_monthly_fee"
                                            />
                                        </Grid>
                                        
                                    </Grid>
                                    <Grid container>
                                        <Grid item md={4}>
                                            <BooleanInput
                                                resource="is_active"
                                                source="is_active"
                                            />
                                        </Grid>
                                        <Grid item md={4}>
                                            <NumberInput
                                                resource="minimum_fee"
                                                source="minimum_fee"
                                            />
                                        </Grid>
                                        <Grid item md={4}>
                                            <NumberInput
                                                resource="maximum_fee"
                                                source="maximum_fee"
                                            />
                                        </Grid>
                                    </Grid>
                                    
                                </Grid>
                            </Grid>
                            <Spacer />

                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            undoable={true}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="commands"
                        />
                    </Card>
                </Box>
            )}
        />
    );
};
const QuickBookAccountEdit = (props: EditProps) => {
    const classes = useEditStyles();
    return (
        <Edit
            title={"Accounts Edit"}
            classes={classes}
            {...props}
            component="div"
        >
            <QuickBookAccountForm />
        </Edit>
    );
};

export default QuickBookAccountEdit;
