import { stringify } from 'query-string';
import { DataProvider, fetchUtils } from 'ra-core';


export default (
    apiUrl: string,
    httpClient = fetchUtils.fetchJson,
    countHeader: string = 'Content-Range'
): DataProvider => ({
    getList: (resource, params) => {
        let axios = require('axios')
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        const filter = params.filter;
        if (filter.store == null) {
            filter.store = [];
        }
        var data = JSON.stringify({
            "stores": filter.store,
            "daily": filter.daily,
            "from": filter.daily ? filter.from : filter.from?.toString().substring(0, 7),
            "to": filter.daily ? filter.to : filter.to?.substring(0, 7),
            "page": page,
            "perPage": perPage,
            "sortField": field,
            "sortOrder": order,
            "service": "acenda-fees",
            "store":"acendacom"
        });

        var config = {
            method: 'post',
            url: process.env.REACT_APP_SERVER_URL + resource,
            headers: {
                'Content-Type': 'application/json',
                'authorization': 'Bearer ' + window.localStorage.getItem("accessToken")
            },
            data: data
        };
        console.log(`here`)
        console.log(config.data)

        return axios(config)
            .then(function (response: any) {
                return response.data;
            })
            .catch(function (error: any) {
                console.log(error);
            });
    },

    getOne: (resource, params) => {
        var axios = require('axios');
        var data = JSON.stringify({
          "store": "acendacom",
          "service": "acenda-fees",
          "id": params.id
        });
        
        var config = {
          method: 'post',
          url: process.env.REACT_APP_SERVER_URL + resource,
          headers: { 
            'Authorization': 'Bearer ' + window.localStorage.getItem('accessToken'), 
            'Content-Type': 'application/json', 
          },
          data : data
        };
        
        return axios(config)
        .then(function (response: any) {
          console.log(response.data)
          return response;
        })
        .catch(function (error: any) {
          console.log(error);
        });
    },

    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ data: json }));
    },

    getManyReference: (resource, params) => {
        alert("Feautre is not implemented!");
        return Promise.resolve(3).then(_ => (params as any));
    },

    update: (resource, params) => {
        let axios = require('axios')

        var data = JSON.stringify({
            "store": "acendacom",
            "service": "acenda-fees",
            ...params.data
        });
        
        var config = {
            method: 'post',
            url: process.env.REACT_APP_SERVER_URL + resource + '/update',
            headers: {
                'Content-Type': 'application/json',
                'authorization': 'Bearer ' + window.localStorage.getItem("accessToken"),
                'Cookie': '8shRtJOEcfu5HjW9f6gSQNMlu6pkUWYbZ0005mxP=eyJpdiI6IitrUjFEcmxUWmMxWW1haHlvOTBId3c9PSIsInZhbHVlIjoiNEcwS1V3MTVZcFVmck5VbnhWdkt2NHE3WWJqbWtIK3l1QkhQekdiS0VUeTgzT1YrRjhFbkNzaTZRWms1clFtUjZqZnpjd3FnVXZNd1k0OThCQUNSSzVpWEJCU3lhQXZVSXNCSFhPdTNDZ2RVZDE3djdSWTBcL1dyY3dudmJwdDJYa1BhcitxeFlKSVR6M0hKZHdzUGVrUUJqdUt2a1hJMFwvZDV1OTEzM2tyVU5iaE9SVUJoM3dIOG82VlRjRU1PYVk1WTZiUnJYcGttdHI4ZjhGeng5K0E0UmtOblRYK0JPRVN5eFRaeDJJV0xRPSIsIm1hYyI6IjVhM2QyNDI4YThlYzM4YmEzNTk5MGIwOGU4OTI2MjQ0MWRkNjU4MmRmYzNlYjE5YTM1ZjVjN2M5YmMyMDI4YmIifQ%3D%3D; laravel_session=eyJpdiI6InZHczRqeWlQNWJxY3FGczFBSzZ3TUE9PSIsInZhbHVlIjoiQkpoKzVmRUsyWTB6WVZoQmlKUGxWZndUQklvM0twZGNOQW5vcXZXRzlDT2o3dlwvczdaVHFcL0hqOU5qXC9HSXZMSiIsIm1hYyI6IjNjYjBlMDY1OGI5MmIwMDk0OWNlNmI1MDg5OWU0OWJmNGFlNDVhNGQwZDc1ZmFkZjE5MDc1OGJiZjNiZGUzZGIifQ%3D%3D'

            },
            data: data
        };

        return axios(config)
            .then(function (response: any) {
                return response;
            })
            .catch(function (error: any) {
                console.log(error);
            });
    },

    updateMany: (resource, params) => {
        alert("Feautre is not implemented!");
        return Promise.resolve(3).then(_ => (params));
    },

    create: (resource, params) => {
        alert("Feautre is not implemented!");
        return Promise.resolve(3).then(_ => (params));
    },

    delete: (resource, params) => {
        alert("Feautre is not implemented!");
        return Promise.resolve(5).then(_ => _ as any);
    },

    deleteMany: (resource, params) => {
        alert("Feautre is not implemented!");
        return Promise.resolve(5).then(_ => _ as any);
    },

});


