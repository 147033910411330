import {
    List,
    ListProps,
    Datagrid,
    TextField,
    BooleanInput,
    NumberField,
    BooleanField,
    AutocompleteArrayInput,
    NumberInput,
    TextInput,
} from 'react-admin';
import QuickBookAccountEdit from './QuickBookAccountsShow';

const stores = window.localStorage.getItem('stores') ? JSON.parse(window.localStorage.getItem("stores") as string).map((store : string) => ({id: store, name: store})) : [];

const listFilters = [
    <AutocompleteArrayInput choices={stores} source="store" alwaysOn ></AutocompleteArrayInput>,
];


const QuickBookAccountsList = (props: ListProps) => {

    return (
        <List
            {...props}
            filters={listFilters}
            hasEdit={true}
            perPage={25}
            sort={{ field: 'id', order: 'desc' }}
        >
            <Datagrid expand={<QuickBookAccountEdit />}>
                <TextField source="customer_id" />
                <TextField source="store_name" />
                <TextField source="sales_percentage" />
                <TextField source="minimum_fee" />
                <TextField source="maximum_fee" />
                <TextField source="fixed_monthly_fee" />
                <BooleanField source="is_active" />
            </Datagrid>
        </List>
    );
};

export default QuickBookAccountsList;
