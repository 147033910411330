import React, { useState, useEffect, useCallback, CSSProperties } from 'react';
import { useVersion, useDataProvider } from 'react-admin';
import { useMediaQuery, Theme } from '@material-ui/core';
import { subDays } from 'date-fns';

import Welcome from './Welcome';
import MonthlyRevenue from './MonthlyRevenue';
import NbNewOrders from './NbNewOrders';
import PendingOrders from './PendingOrders';
import PendingReviews from './PendingReviews';
import NewCustomers from './NewCustomers';
import OrderChart from './OrderChart';

import { Customer, Order, Review } from '../types';

interface OrderStats {
    revenue: number;
    nbNewOrders: number;
    pendingOrders: Order[];
}

interface CustomerData {
    [key: string]: Customer;
}

interface State {
    nbNewOrders?: number;
    nbPendingReviews?: number;
    pendingOrders?: Order[];
    pendingOrdersCustomers?: CustomerData;
    pendingReviews?: Review[];
    pendingReviewsCustomers?: CustomerData;
    recentOrders?: Order[];
    revenue?: string;
}

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em' },
};

const Spacer = () => <span style={{ width: '1em' }} />;
const VerticalSpacer = () => <span style={{ height: '1em' }} />;

const Dashboard = () => {

const [state, setState] = useState<State>({});
const version = useVersion();
const dataProvider = useDataProvider();
const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs')
);
const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
);



const {
    nbNewOrders,
    nbPendingReviews,
    pendingOrders,
    pendingOrdersCustomers,
    pendingReviews,
    pendingReviewsCustomers,
    revenue,
    recentOrders,
} = state;
return isXSmall ? (
    <div>
        <div style={styles.flexColumn as CSSProperties}>
            <Welcome />
            {/* <MonthlyRevenue value={revenue} /> */}
            <VerticalSpacer />
            {/* <NbNewOrders value={nbNewOrders} /> */}
            <VerticalSpacer />
            {/* <PendingOrders
                    orders={pendingOrders}
                    customers={pendingOrdersCustomers}
                /> */}
        </div>
    </div>
) : isSmall ? (
    <div style={styles.flexColumn as CSSProperties}>
        <div style={styles.singleCol}>
            <Welcome />
        </div>
        <div style={styles.flex}>
            {/* <MonthlyRevenue value={revenue} /> */}
            <Spacer />
            {/* <NbNewOrders value={nbNewOrders} /> */}
        </div>
        <div style={styles.singleCol}>
            {/* <OrderChart orders={recentOrders} /> */}
        </div>
        <div style={styles.singleCol}>
            {/* <PendingOrders
                    orders={pendingOrders}
                    customers={pendingOrdersCustomers}
                /> */}
        </div>
    </div>
) : (
    <>
        <Welcome />
        <div style={styles.flex}>
            <div style={styles.leftCol}>
                <div style={styles.flex}>
                    {/* <MonthlyRevenue value={revenue} /> */}
                    <Spacer />
                    {/* <NbNewOrders value={nbNewOrders} /> */}
                </div>
                <div style={styles.singleCol}>
                    {/* <OrderChart orders={recentOrders} /> */}
                </div>
                <div style={styles.singleCol}>
                    {/* <PendingOrders
                            orders={pendingOrders}
                            customers={pendingOrdersCustomers}
                        /> */}
                </div>
            </div>
            <div style={styles.rightCol}>
                <div style={styles.flex}>
                    {/* <PendingReviews
                            nb={nbPendingReviews}
                            reviews={pendingReviews}
                            customers={pendingReviewsCustomers}
                        /> */}
                    <Spacer />
                    {/* <NewCustomers /> */}
                </div>
            </div>
        </div>
    </>
);
};

export default Dashboard;
