import { AuthProvider } from 'react-admin';

const authProvider: AuthProvider = {
    login: async ({ username, password }) => {
        let axios = require("axios");

        var config = {
            method: 'post',
            url: process.env.REACT_APP_SERVER_URL + 'oauth',
            headers: {
                'Content-Type': 'application/json',
            },
            data: { username: username, password: password }
        };

        return axios(config)
            .then((response: any) => {
                console.log(response)
                localStorage.setItem("accessToken", response.data)
                localStorage.setItem("username", username)
                return Promise.resolve()
            })
            .catch((error: any) => {
                console.log(error);
                return Promise.reject()
            });
    },
    logout: () => {
        localStorage.removeItem('username');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('stores')
        return Promise.resolve();
    },
    checkError: () => Promise.resolve(),
    checkAuth: () =>
        localStorage.getItem('username') ? Promise.resolve() : Promise.reject(),
    getPermissions: () => Promise.reject('Unknown method'),
    getIdentity: () =>
        Promise.resolve({
            id: 'user',
            fullName: 'Gene L.',
            avatar: 'https://pngset.com/images/anonymous-profile-grey-person-sticker-glitch-empty-profile-picture-icon-baseball-cap-hat-clothing-apparel-transparent-png-723261.png'
        }),
};

export default authProvider;
